<template>
  <v-app>
    <v-main>
      <v-container>
        <h1>Conférences</h1>
        <div v-if="!conferenceAffichage && !cacherInfoPageSecours">
          <v-card
            elevation="4"
            class="mt-4 mb-4"
            v-for="conference in conferences"
            :key="conference.nom"
          >
            <v-card-title>{{ conference.nom }}</v-card-title>
            <v-card-actions>
              <v-btn
                color="deep-purple"
                text
                @click="accederConference(conference)"
              >
                Accéder à la conférence
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div v-if="conferenceAffichage">
          <v-btn color="deep-purple" text @click="accederConference(null)">
            &lt; Retourner à la liste des conférences
          </v-btn>
          <cnx-video-player :url-presentation="conferenceAffichage.url" />
        </div>
        <div v-if="cacherInfoPageSecours">
          <v-alert text color="info">
            <div>
              Les conférences ne sont actuellement pas disponible. Veuillez
              communiquez avec le SOUTIEN TECHNIQUE à
              webdiffusion@connexence.com ou au 418 380-5815.
            </div>
          </v-alert>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="">
import Vue from "vue";
import CnxVideoPlayer from "./CnxVideoPlayer.vue";
import axios from "axios";

export default Vue.extend({
  components: {
    CnxVideoPlayer,
  },
  data() {
    return {
      conferenceAffichage: null,
      conferences: [
        {
          nom: "Jeudi : séances plénières AM",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq1.m3u8",
        },
        {
          nom: "Jeudi AM / séance facultative #1: Désintensifier le dépistage et le traitement : quand s'arrêter chez la personne âgée ?",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq1.m3u8",
        },
        {
          nom: "Jeudi AM / séance facultative #2: L'ostéoporose, une maladie ?",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq2.m3u8",
        },
        {
          nom: "Jeudi AM / séance facultative #3: Surmédicalisation en pédiatrie : distinguer la normalité de l'état pathologique",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq3.m3u8",
        },
        {
          nom: "Jeudi : séances plénières PM",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq1.m3u8",
        },
        {
          nom: "Vendredi : séances plénières AM",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq1.m3u8",
        },
        {
          nom: "Vendredi AM / séance facultative #4: Pratique avisée: mieux choisir nos évaluations de laboratoire",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq1.m3u8",
        },
        {
          nom: "Vendredi AM / séance facultative #5: Sommes-nous en train de surmédicaliser la nutrition",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq2.m3u8",
        },
        {
          nom: "Vendredi PM / séance facultative #6: Surmédicalisation en senté mentale : voir clair dans le TDAH, le TSA et la douance",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq1.m3u8",
        },
        {
          nom: "Vendredi PM / séance facultative #7: Traiter l'appareil locomoteur avec soin : pertinence et données probantes",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq1.m3u8",
        },
        {
          nom: "Vendredi : séances plénières PM",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq1.m3u8",
        },
      ],
      cacherInfoPageSecours: null,
    };
  },
  created() {
    this.getParametres();
  },
  methods: {
    accederConference(conference) {
      this.conferenceAffichage = conference;
    },
    getParametres() {
      axios
        .get(
          "https://api-toucan.connexence.com/api/cnx-configurations/cacherInfoPageSecours"
        )
        .then((response) => {
          console.log(response);
          this.cacherInfoPageSecours = response.data;
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
  },
});
</script>

<style lang="scss" scoped></style>
